import React from 'react'
import { graphql } from 'gatsby' 
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/Layout'
import Section from '../components/Section'
import PricingWidgetSection from '../components/PricingWidgetSection'
import BoxedCTASection from '../components/BoxedCTASection'
import Sidebar from '../components/Sidebar'
import Hero from '../components/Hero'

import './customer-story.scss'


export default ({data}) => {
  const page = data.contentfulCustomerStory
  const { title, video } = page
  const intro = page.intro && page.intro.childMarkdownRemark && page.intro.childMarkdownRemark.html
  const heroImageUrl = page.image && page.image.file && page.image.file.url
  
  const relatedContent = data.relatedContent && data.relatedContent.edges.map(({ node }) => ({...node}))
  
  return (
    <Layout
      className="CustomerStoryPage"
      title={`${title} - Customer Story`}
    >
      <Hero
        backgroundProps={{
          style: {
            backgroundImage: heroImageUrl ? `url(${heroImageUrl})` : null
          }
        }}
      >
        <h1>{title}</h1>
      </Hero>
      <Section>
        <Container>
          <Row>
            <Col lg={8}>
              {video && video.url &&
                <div className="embed-responsive embed-responsive-16by9 bg-light mb-3">
                  <iframe width="640" height="360" src={video.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
                </div>
              }
              {intro &&
                <div dangerouslySetInnerHTML={{ __html: intro }} />
              }
            </Col>

            <Col lg={4} className="pt-5 pt-lg-0">
              <Sidebar
                relatedContentTitle="Related content"
                relatedContent={relatedContent}
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <BoxedCTASection />
      <PricingWidgetSection />
    </Layout>
  );
}


export const pageQuery = graphql`
  query CustomerStoryPageByUrl($slug: String!) {
    contentfulCustomerStory(slug: { eq: $slug } ) {
      ...ContentfulCustomerStoryFragment
    }
    relatedContent: allContentfulCustomerStory(filter: {slug: {ne: $slug}}, limit: 3, sort: {order: DESC, fields: date}) {
      edges {
        node {
          __typename
          ...ContentfulCustomerStoryFragment
        }
      }
    }
  }
`
